import { render, staticRenderFns } from "./miniBasket.vue?vue&type=template&id=5448db46&scoped=true"
import script from "./miniBasket.vue?vue&type=script&lang=js"
export * from "./miniBasket.vue?vue&type=script&lang=js"
import style0 from "./miniBasket.vue?vue&type=style&index=0&id=5448db46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5448db46",
  null
  
)

export default component.exports